import React, { useEffect, useRef, useState } from "react";
import CrossIcon from "../../assets/images/cross-icon-svg.svg";
import CrossIconWhite from "../../assets/images/cross-icon-svg-white.svg";
import TickIconBlue from "../../assets/images/tick-icon-svg-blue.svg";
import TickIconWhite from "../../assets/images/tick-icon-svg-white.svg";
import { func } from "../../Utilities/logFunc";
import { toast } from "react-toastify";
import {
  createStripeSubscriptionTransaction,
  getSubscriptionTypeList,
} from "../../Utilities/controller";
import Master from "../../assets/images/d-master.png";
import Casual from "../../assets/images/d-casual.png";
import Pro from "../../assets/images/d-pro.png";
import MasterIcon from "../../assets/images/Master.png";
import ProIcon from "../../assets/images/Pro.png";
import CasualIcon from "../../assets/images/Casual.png";

import SuccessPop from "./SuccessPop";

import PaymentChooseOption from "./PaymentChooseOption";
import ProfileDataProvider, {
  useProfileDataContext,
} from "../HomePage/ProfileContext";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
import moment from "moment";
import { logout } from "../../Utilities/logout";
import { toastError } from "../../Utilities/toast";

const SubscriptionTypeList = () => {
  const [list, setList] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const { liteProfile } = useProfileDataContext();
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const subscriptionDetails = JSON.parse(localStorage.getItem('subscriptionDetails'))
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.PREMIUM);
  }
  const fetchList = async () => {
    // console.log(liteProfile?.country)
    let payload = {
      country: localStorage.getItem("country"),
      company: process.env.REACT_APP_COMPANY_ID,
    };
    try {
      const res = await getSubscriptionTypeList(payload);
      func("subscription list 13", res?.data?.data?.[0]?.param?.data);
      setList(res?.data?.data?.[0]?.param?.data);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      toastError(error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlerBuyPlan = async (ind) => {
    setShowPaymentOption(true);
    setActiveIndex(ind);
    //MG_UserID, MG_TimeStamp, MG_PlanAmount
    eventTracking(events.SUBSCRIPTION_PLAN, {
      MG_PlanAmount: list?.[ind].packs[0]?.amount,
    });
  };
  useEffect(() => {
    fetchList();
    if (document.documentElement.dir == "rtl" && contentRef?.current) {
      const contentWidth = contentRef?.current?.clientWidth - contentRef?.current?.scrollWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: 'smooth',
      });
    }
    else {
      const contentWidth = contentRef?.current?.scrollWidth - contentRef?.current?.clientWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: 'smooth',
      });
    }

  }, []);

  return (
    // <ProfileDataProvider>
    <div className="content">
      <div className="inner">
        <div class="row gy-5">
          <div class="col-12">
            <div class="mb-4 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">
                {t("premium.mobily_premium")}
              </h2>
            </div>
            <div class="pricingTable" ref={contentRef} >
              <table class="table m-0">
                <thead>
                  <tr>
                    <th width="26%" scope="col"></th>
                    <th width="19%" scope="col">
                      {/* <img class="mb-4 icon-100" src={Tier1Icon} alt="" /> */}
                      <div class="mb-4 icon-100" ></div>
                      <h4 class="text-white">{list?.[0]?.planName}</h4>
                      {/* <p class="mb-0 text-white">Tier 1</p> */}
                    </th>
                    <th width="19%" scope="col">
                      <img class="mb-4 icon-100" src={CasualIcon} alt="" />
                      <h4 class="text-white">
                        {list?.[1]?.planName || "Casual"}{" "}
                      </h4>
                      {/* <p class="mb-0 text-white">Tier 2</p> */}
                    </th>
                    <th width="18%" scope="col">
                      <img class="mb-4 icon-100" src={ProIcon} alt="" />
                      <h4 class="text-white">
                        {list?.[2]?.planName || "Pro"}{" "}
                      </h4>
                      {/* <p class="mb-0 text-white">Tier 3</p> */}
                    </th>
                    <th width="18%" scope="col">
                      <img class="mb-4 icon-100" src={MasterIcon} alt="" />
                      <h4 class="text-white">
                        {list?.[3]?.planName || "Master"}{" "}
                      </h4>
                      {/* <p class="mb-0 text-white">Tier 4</p> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="5">
                      <h5>{t("premium.features")}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.prize_pool_multipler")}</td>
                    <td class="free-package bodyHeavyFont">{(list?.[0]?.multiplier) * 100 || 100}%</td>
                    <td class="casual-package bodyHeavyFont">
                      {(list?.[1]?.multiplier) * 100 || 100}%
                    </td>
                    <td class="pro-package bodyHeavyFont">{(list?.[2]?.multiplier) * 100 || 150}%</td>
                    <td class="master-package bodyHeavyFont">
                      {(list?.[3]?.multiplier) * 100 || 200}%
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.joining")}</td>
                    <td>{t("premium.daily1")}</td>
                    <td>{t("premium.unlimited")}</td>
                    <td>{t("premium.unlimited")}</td>
                    <td>{t("premium.unlimited")}</td>
                  </tr>
                  <tr>
                    <td>{t("premium.statistic")}</td>
                    <td>{t("premium.basic_statistics")}</td>
                    <td>{t("premium.basic_statistics")}</td>
                    <td>{t("premium.basic_statistics")}</td>
                    <td>
                      {t("premium.advance_statistics")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.subscription_badge")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img className="icon-75" src={Casual} alt="" />
                    </td>
                    <td>
                      <img className="icon-75" src={Pro} alt="" />
                    </td>
                    <td>
                      <img className="icon-75" src={Master} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.leaderboard_highlight")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.premium_support")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.exclusive_skins_avatars")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                  </tr>

                  <tr>
                    <td>{t("premium.ad_free_experience")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                  </tr>

                  <tr>
                    <td>{t("premium.private_contest_hosting")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                  </tr>
                </tbody>
                {func(
                  "list 126 *****************",
                  localStorage.getItem("planName")
                )}
                <tfoot>
                  <tr>
                    <td></td>

                    {(!JSON.parse(localStorage.getItem("premiumUser")) && !JSON.parse(localStorage.getItem("isUserSubscribed"))) ||
                      localStorage.getItem("planName") ==
                      ("Free" || "undefined") ? (
                      <td>
                        <h5 class="mb-3 text-white d-flex justify-content-center"><p className="mx-1">{t("tournaments.free")}</p></h5>
                        <span class=" btn btn-outline-golden disabled">
                          {t("premium.currently_active")}
                        </span>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      <h5 class="mb-3 text-white d-flex justify-content-center">
                        <p className="mx-1">{list?.[1]?.packs?.[0]?.amount}</p>
                        <p>{list?.[1]?.currencyID?.symbol}/ {t("premium.month")}</p>
                      </h5>
                      <span
                        onClick={() => handlerBuyPlan(1)}
                        className={`${localStorage.getItem("planName") == process.env.REACT_APP_BRONZE
                            ? "btn btn-outline-golden disabled"
                            : "btn btn-gradient-blue"
                          } `}
                      >
                        {localStorage.getItem("planName") == process.env.REACT_APP_BRONZE
                          ? t("premium.currently_active")
                          : t("premium.subscribe")}{" "}
                      </span>
                    </td>

                    <td>
                      <h5 class="mb-3 text-white d-flex justify-content-center">
                        <p className="mx-1">{list?.[2]?.packs?.[0]?.amount} </p>
                        {/* <span className="mx-3">{" "}</span> */}
                       <p className="">{list?.[2]?.currencyID?.symbol}/ {t("premium.month")}</p>
                        
                      </h5>
                      <span
                        onClick={() => handlerBuyPlan(2)}
                        className={`${localStorage.getItem("planName") == process.env.REACT_APP_SILVER
                            ? "btn btn-outline-golden disabled"
                            : "btn btn-gradient-blue"
                          } `}
                      >
                        {localStorage.getItem("planName") == process.env.REACT_APP_SILVER
                          ? t("premium.currently_active")
                          : t("premium.subscribe")}{" "}
                      </span>
                    </td>
                    <td>
                      <h5 class="mb-3 text-white d-flex justify-content-center">
                        <p className="mx-1">{list?.[3]?.packs?.[0]?.amount}</p>
                        <p>{list?.[3]?.currencyID?.symbol}/ {t("premium.month")}</p>
                      </h5>
                      <span
                        onClick={() => handlerBuyPlan(3)}
                        className={`${localStorage.getItem("planName") == process.env.REACT_APP_GOLD
                            ? "btn btn-outline-golden disabled"
                            : "btn btn-gradient-blue"
                          } `}
                      >
                        {localStorage.getItem("planName") == process.env.REACT_APP_GOLD
                          ? t("premium.currently_active")
                          : t("premium.subscribe")}
                      </span>
                    </td>
                    
                  </tr>
                 
                </tfoot>
              </table>
              {
                    (subscriptionDetails?.isPremium || subscriptionDetails?.isUserSubscribed) &&
                    <>
                    <div className='row mt-5'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                      <span className='text-light-color'>{t("premium.startDate")}: {moment(subscriptionDetails.startDate).format("Do MMMM YYYY HH:mm")}
                      </span><span style={{marginLeft: '40px'}}>{t("premium.endDate")}: {moment(subscriptionDetails.endDate).format("Do MMMM YYYY HH:mm")}</span>
                      {/* <p className='text-light-color mt-3'>Notes: {subscriptionDetails.isPremium ? 'You are subscribed' : 'You are not subscribe to premium yet. Please recharge your Cellcard credits for the same'}</p> */}
                      {
                       (subscriptionDetails?.isPremium && subscriptionDetails?.isUserSubscribed)  ? 
                        <p className='text-light-color mt-3'>{t("premium.notes")} : {t("premium.subscribing")}</p>
                        : 
                        (!subscriptionDetails?.isPremium && subscriptionDetails?.isUserSubscribed) ?
                        <p className='text-light-color mt-3'>{t("premium.notes")} : {t("premium.suspended")}</p>
                        :
                        (subscriptionDetails?.isPremium && !subscriptionDetails?.isUserSubscribed) ?
                        <p className='text-light-color mt-3'>{t("premium.notes")} : {t("premium.resubscribe")}</p>
                        : ''
                      }
                      
                    
                      </div></div>
                    </>
                  }
            </div>
          </div>
        </div>
        {<SuccessPop />}
        {showPaymentOption && (
          <PaymentChooseOption
            showPaymentOption={showPaymentOption}
            setShowPaymentOption={setShowPaymentOption}
            list={list?.[activeIndex]}
          />
        )}
      </div>
    </div>
    // </ProfileDataProvider>
  );
};

export default SubscriptionTypeList;
